/* eslint-disable  */
/**
 * Created by mq on 17-6-17.
 * 提供前端api，获取用户信息
 */
const commonApi = require("./config");
import { wxGwPost, setGwToken, clearToken } from "./httpApi";

const debug = require("debug")("@wxLoginApi");
const _ = require("lodash");

let $location = null;
let $router = null;
let loginSuccessed = false;
let onReadyCallbacks = [];
let userInfo = {};
/**
 * 从url中读取请求字符串
 * @param {string} name
 * @return {*}
 */
function getQueryString(name) {
    //   debug('name=======', name);
    const r = $location.href.match(
        // eslint-disable-next-line
        new RegExp(`([\?|&])${name}=([^|&]*)(&|$)`)
    );
    if (r !== null) {
        const str = decodeURI(r[2]);
        const pos = str.indexOf("#/");
        if (pos >= 0) {
            return str.substr(0, pos);
        }
        return str;
    }

    // const r = $location.href.match( // eslint-disable-next-line
    //   new RegExp(`([\?|&])${name}=([^#&]+)`));
    // if (r != null) return decodeURI(r[2]);

    return null;
}
let sceneid = null;

/**
 * 验证用户是否在企业号内
 * @param {用户id} userid
 */
function checkUserFrom(userid) {
    if (userid.indexOf("openid_") > -1) {
        return false;
    } else {
        return true;
    }
}

function toPath(userid) {
    debug("非企业号用户登录验证", userid, checkUserFrom(userid));
    // 获取用户信息成功，页面跳转
    const path = getQueryString("path");
    // 分享的文章和消息可以查看详情
    if (!checkUserFrom(userid)) {
        $router.replace("noPass");
    } else {
        if ($router && path) {
            $router.replace(path);
        }
    }
}

function initUserData() {
    // 记录扫码id
    sceneid = getQueryString("sceneid");
    const usercode = getQueryString("code");
    const path = getQueryString("path");
    const url = $location.href;
    let postData = {};
    postData["postUrl"] = window.location.href;
    //   debug('==================================path', path, usercode, url);
    // 进行用户认证登录会话
    wxGwPost("/wx/check", {
            url,
            usercode,
            agentid: commonApi.getAgentid(), // 扫码登录应用
        })
        .then((result) => {
            debug("initUserData 用户登录成功", result);
            postData["step"] = result.user;
            if (result.user) {
                debug("USER LOGIN READY:", result);
                // 成功获取用户信息
                userInfo = result.user;

                // 记录token
                if (result.token) {
                    setGwToken(result.token);
                }
                // 当angular Ready时调用回调函数
                onReadyCallbacks.forEach((cb) => {
                    debug("CALL LOGIN Ready()");
                    cb();
                });
                loginSuccessed = true;
                onReadyCallbacks = [];
                debug("--------toPath 验证", userInfo.mobile);
                // 非企业号用户登录成功 && 目的路径为文章详情时，允许跳转，其他的暂时不可
                toPath(userInfo.mobile);
                postData["step01"] = "ok";
            } else if (result.openId) {
                // 检测用户原地址-- 转换得系统内地址
                // 获取用户源地址---转换得系统内地址--添加用户非企业内部人员信息
                // 成功获取用户信息
                userInfo["openId"] = result.openId;

                const path = getQueryString("path");
                $router.replace(`${path}`);
                // if (path.indexOf("voteDetail") > -1) {
                //   // 非企业号用户投票分享界面
                //   console.log("非企业号用户投票分享界面======", path);
                //   $router.replace(`voteDetailShare`);
                // }
                postData["step02"] = { openId: result.openId };
            } else {
                debug("NOT USER INFO", result);
                postData["step03"] = "ok";
                if (result.redirect) {
                    postData["step04"] = "ok";
                    debug("11111111111111111111", result.redirect);
                    $location.replace(result.redirect);
                }
            }
        })
        .catch((e) => {
            clearToken();
            postData["catch"] = e;

            // res.status(400).json({ err: 'User Not Found' });
            const _redirectUrl = _.get(e, ["response", "data", "redirect"]);
            postData["_redirectUrl"] = _.get(e, ["response", "data"]);

            if (_redirectUrl) {
                postData["step05"] = "ok";
                // debug('22222222222222222222222222', _redirectUrl);
                $location.replace(_redirectUrl);
                return;
            } else {
                // alert(`1111111111:${path} ${$router}`);
                // _d('登录跳转', path)
                // 获取用户信息成功，页面跳转
                postData["step06"] = "ok";
                if ($router && path) {
                    // $router.replace(path);
                    postData["step07"] = "ok";
                    if (path.indexOf("shareDetail") > -1) {
                        $router.replace(path);
                    } else {
                        postData["step08"] = "ok";
                        clearToken();
                    }
                }
            }

            // 其他登录失败情况，如登录接口不通
        });
}

function initLocalData() {
    // 成功获取用户信息

    // userInfo = {
    //   userid: "HuangXiaoCheng",
    //   name: "黄晓程",
    //   mobile: "13580580586",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };
    // userInfo = {
    //   userid: "SunGuoLiang",
    //   name: "孙国良",
    //   mobile: "18646179224",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };

    userInfo = {
            userid: "1548992608198924b0cc19372bdb091fd283e490f4d3b15637fff4d",
            name: "彭晓彬",
            mobile: "13430208630",
            avatar: "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
        }
        // userInfo = {
        //         userid: "linzhihui",
        //         name: "林云辉",
        //         mobile: "13682253443",
        //         avatar: "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
        //     }
        // userInfo = {
        //   userid: "1547450648692650f562f18b4c30786829287547e63eae219b95fc6",
        //   name: "何博",
        //   mobile: "13286558555",
        //   avatar:
        //     "https://wework.qpic.cn/bizmail/dZFGcOW4yibxWlzSOic0hSd2W6LJxeIWzFIZxJaC47zvcuDNYxRvKffw/0"
        // };

    //  单位管理员统计
    // userInfo = {
    //   userid: "15508113192011a7df6fe20cc0c9057586d3220310f21c43e42071a",
    //   name: "苏伟东",
    //   mobile: "13902294437",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };
    // 株洲车辆段
    // userInfo = {
    //   userid: "15472166214280d4b4fac5b88ebe8971a031fcf77c82b045484073b",
    //   name: "王茜",
    //   mobile: "13973309733",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };

    // userInfo = {
    //   userid: "GanLiSha",
    //   name: "甘丽莎",
    //   mobile: "15874978168",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };
    // userInfo = {
    //   userid: "15474595898986003d6bd5e40d34ef823c06f0371baa88e2f3bce78",
    //   name: "李明",
    //   mobile: "15874978168",
    //   avatar:
    //     "https://p.qlogo.cn/bizmail/4qoGYib0hJWd1QnicPVcpzkUYE2dCNreKicGaWnzTFH737TJibJc2VXVYg/0",
    // };
    // userInfo = {
    //   userid: "zhengdongyang",
    //   name: "郑东阳",
    //   mobile: "15810661181",
    //   avatar:
    //     "https://wework.qpic.cn/bizmail/dZFGcOW4yibxWlzSOic0hSd2W6LJxeIWzFIZxJaC47zvcuDNYxRvKffw/0",
    // };

    // userInfo = {
    //   userid: "1611743160580a1068586efaeb67ff34fa00396aacc5cbd5f6d4e75",
    //   name: "柯竞舟",
    //   mobile: "18608983592",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };

    // userInfo = {
    //   userid: "16255552018898ba0c2ef720bf61086ddf9429c6072bdf1f46c3f5e",
    //   name: "将夜",
    //   mobile: "18310343563",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };
    // userInfo = {
    //   userid: "13919267170",
    //   name: "刘玉莹",
    //   mobile: "13522042293",
    //   avatar:
    //     "https://p.qlogo.cn/bizmail/4qoGYib0hJWd1QnicPVcpzkUYE2dCNreKicGaWnzTFH737TJibJc2VXVYg/0",
    // };

    // userInfo = {
    //   userid: "164447890081071bd161371820bd26abbba857e298d930ec32c24c1",
    //   name: "李振企",
    //   mobile: "18310343563",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };

    // userInfo = {
    //   userid: "openid_xxxx",
    //   name: "无权限用户",
    //   mobile: "18310343563",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };

    // userInfo = {
    //   userid: "1547102631406ee06d0f799990a0921a478c996e03bf090113b25a3",
    //   name: "王春润",
    //   mobile: "18873667766",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };

    // 长沙电务段管理员
    // userInfo = {
    //   userid: "154899758668296be69f2a8537360e28acf6c548bf7bd5d7c9e0820",
    //   name: "阳云志",
    //   mobile: "15292198693",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };

    // 广州车站领导班子
    // userInfo = {
    //   userid: "15508232526477613f092e4f68019a0872dc77448b872de6a0c803c",
    //   name: "陈叙光",
    //   mobile: "13802983825",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };

    // 株洲车辆段
    //   userInfo = {
    //   userid: "154918476013138b9f9739b49019e516852d8c73d7a1fd020fa9215",
    //   name: "刘双合",
    //   mobile: "13707338445",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };

    // 电子设备检修车间
    // userInfo = {
    //   userid: "YinJianCheng",
    //   name: "尹建程",
    //   mobile: " 13487458686",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };

    // userInfo = {
    //   userid: "166744289493995c82ff76a1d9c46b504b30f410d04f8364d5649a9",
    //   name: "xxxxxx",
    //   mobile: "13487458686",
    //   avatar:
    //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
    // };
    //
    // 13802983825
    const path = getQueryString("path");
    // 进行用户认证登录会话
    wxGwPost("/wx/testCheck", userInfo)
        .then((result) => {
            debug("用户登录成功", result);
            setGwToken(result.token);
            // 当angular Ready时调用回调函数
            onReadyCallbacks.forEach((cb) => {
                debug("CALL LOGIN Ready()");
                cb();
            });
            loginSuccessed = true;
            onReadyCallbacks = [];
            // 非企业号用户登录成功 && 目的路径为文章详情时，允许跳转，其他的暂时不可
            toPath(userInfo.mobile);
        })
        .catch((e) => {
            // 当angular Ready时调用回调函数
            onReadyCallbacks.forEach((cb) => {
                debug("CALL LOGIN Ready()");
                cb();
            });
            loginSuccessed = true;
            onReadyCallbacks = [];
        });
}

function parseJwtPayload(token) {
    // 检查是否为合法JWT格式（包含两个点）
    if (typeof token !== 'string' || token.split('.').length !== 3) {
        console.error('Invalid JWT format');
        return null;
    }

    try {
        // 分割并获取payload部分
        const base64Url = token.split('.')[1];
        
        // 添加Base64缺失的填充字符
        let base64 = base64Url
            .replace(/-/g, '+')
            .replace(/_/g, '/');
        
        switch (base64.length % 4) {
            case 2: base64 += '=='; break;
            case 3: base64 += '='; break;
        }

        // 解码并解析JSON
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join('')
        );

        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error('Failed to parse JWT payload:', error);
        return null;
    }
}

// 使用示例
/**
 * 定时刷新token时间，防止过期
 */
const refreshToken = async() => {
    try {
        const ret = await wxGwPost("/code/check", {});
        
        // const ret = await wxGwPost("/wx/refresh", {}); // 新接口，改变gtWxPage的token时间
        if (ret.user) {
            // 保存userInfo，保存token
            userInfo = ret.user;
            setGwToken(ret.token);
            let userid = parseJwtPayload(ret.token);
            userInfo.userid = userid.userid;
        }
    } catch (e) {
        debug("refreshToken eeeeee", e);
    }
};
export default {
    refreshToken,
    getSceneId() {
        return sceneid; // 扫码场景id
    },
    getUser() {
        return userInfo;
    },
    init(bLocalTest, router) {
        // console.log("登录=====", bLocalTest);
        $location = document.location;
        $router = router;
        // 判断是否有取过数据
        if (!_.isEmpty(this.userInfo)) {
            return;
        }
        if (bLocalTest) {
            // 本地测试，使用模拟数据
            initLocalData();
        } else {
            // 实际环境，获取用户信息
            initUserData();
        }
    },
    onReady(cb) {
        if (loginSuccessed) {
            // 已经成功登录,直接执行
            debug("EXEC LOGIN Ready()");
            setTimeout(cb);
        } else {
            // 没有登录,添加回调
            debug("PUSH LOGIN Ready()");
            onReadyCallbacks.push(cb);
        }
    },
};